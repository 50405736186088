import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Content from '../components/Content.js'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import IconsSite from '../components/icons/iconsSite.js'

import Billboard from '../components/billboard/billboard.js'
import Card from '../components/card/card.js'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteTagline = get(this, 'props.data.site.siteMetadata.tagline')
    const thinkingImage = get(this, 'props.data.file.childImageSharp.fluid')
    const melissaImage = get(this, 'props.data.anotherFile.childImageSharp.fluid')
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    const wordpressposts = get(this, 'props.data.allWordpressPost.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        
        <section className="content" style={{marginBottom: '2rem'}}>
          {/* <Img className="mainImage" fluid={thinkingImage}/> */}
          <h1 className="tagline" dangerouslySetInnerHTML={{ __html: siteTagline }}></h1>
          <div className="profile">
          <div className="profileImageWrapper">
              <Img className="profileImage" fluid={melissaImage} />
              <a className="btn" href="https://www.psychologytoday.com/us/therapists/melissa-austin-richmond-ky/418459">Psychology Today Profile</a>
          </div>
            <p>
              <Content content="I believe that every person has the capacity for change and authentic living. I also believe that no one gets there alone.
I have experience helping individuals with a wide variety of concerns including anxiety, depression, impulse control, grief, self-harm and trauma. I enjoy working with people of all ages, but specialize in services to children, adolescents, and their families.
I utilize a person-centered, strengths-based approach that values each person’s unique experiences and abilities. It is an honor any time someone invites me to walk with them on their journey."/>
            </p>
          </div>
          <h2 style={{ textAlign: 'center' }} >Schedule an Appointment</h2>
          <a className="btn solid large icon" href="tel:859-314-1281"><IconsSite name={'phone'} />Call 859-314-1281</a>
          <a className="btn solid large icon" href=" https://www.psychselect.com/cgi-bin/therapyappointment/login.cfm?practitioner=27537&CameFromChooseTherapist=1&CFID=10395738&CFTOKEN=50251619&jsessionid=06304d841b12f6b610403134557138754766
"><IconsSite name={'send'} /> Schedule Online</a>

        </section>

        
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        tagline
      }
    }
    file(relativePath: { eq: "beach.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700){
          ...GatsbyImageSharpFluid
        }
      }
    }
    anotherFile: file(relativePath: { eq: "melissa_austin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700){
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
          }
        }
      }
    }

  }
`
